@if (OverlayVisible) {
  <div class="overlay-b"
  (click)="closeAllPopups()"></div>
}
@if (openPopup) {
  <div class="modal-container" id="shipping-pop-up">
    <div class="modal mr0i fadeIn" (click)="closeAllPopups()">
      <div class="modal-content mrt0i w85pi" [ngClass]="isInfoPopup ? 'b-r0i' : 'b-r20'"
        (click)="$event.stopPropagation()">
        @if (isInfoPopup || popupType == popupTypeConstants?.actionInfo) {
          <div class="pp-g pdt10 pdb10">
            @if (!isInfoPopup) {
              <img [src]="imageConstant?.crossButton" class="pa t0 r8" (click)="closeAllPopups()" />
            }
            @if (popupConfig?.popupIcon) {
              <img class="b-r50" [src]="popupConfig?.popupIcon">
            }
            <div class="pp-1-1 pdl20 pdr20">
              @if (popupHeading) {
                <p class="f16 mrb10 mrt5"
                  [ngClass]="isInfoPopup ? 'tx-dar fw-medium t-left' : 'drk-mode fw-bold t-center'">
                  {{ popupHeading }}
                </p>
              }
              @if (popupText) {
                <p class="mrt0 mrb0"
                [ngClass]="isInfoPopup ? 'f14 tx-med t-left' : 'f11 grey-mode t-center'" [innerHTML]="popupText"></p>
              }
            </div>
          </div>
          <div class="pd3" [ngClass]="isInfoPopup ? 't-right' : 't-center'">
            @if (popupType == popupTypeConstants?.actionInfo ) {
              @for (btn of actionButtons; track btn) {
                <a mat-button (click)="onActionBtnClick(btn)">
                  <span class="f14 fw-medium" [ngClass]="btn.styleClass">{{btn?.actionName}}</span>
                </a>
              }
            } @else {
              <a mat-button (click)="closeAllPopups()">
                <span class="f14 fw-bold tx-pk">GOT IT</span>
              </a>
            }
          </div>
        }
      </div>
    </div>
  </div>
}