import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ImageConstants } from "src/app/constants/application/image-constants";
import { PopupTypeConstants } from "src/app/constants/popup/popup-constants";
import { AppPopupConfigModel } from "src/app/interfaces/popup.model";
@Component({
  selector: "app-popup-component",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements OnChanges {

  @Input() popupType: any;
  @Input() popupHeading: any;
  @Input() popupText: any;
  imageConstant = ImageConstants;
  openPopup: boolean = false;
  OverlayVisible: boolean = false;
  actionButtons: Array<any> = [];
  popupConfig: any = {};
  isInfoPopup: boolean = true;

  public popupTypeConstants = PopupTypeConstants;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.popupType) this.openPopupType(this.popupType, this.popupHeading, this.popupText);
  }

  /**
   * openPopupType method got deprecated. now can open popup with openPopupWithConfig method
   * @deprecated This method is deprecated and should not be used.
   */
  openPopupType(type, header, title) {
    const config: AppPopupConfigModel = {
      popupType: type,
      popupHeading: header,
      popupText: title
    };
    this.openPopupWithConfig(config);
  }
  openPopupWithConfig(config: AppPopupConfigModel) {
    if (config.actionBtns?.length) { this.actionButtons = config.actionBtns; }
    const { popupHeading, popupText, popupType } = config;
    this.popupConfig = config;
    this.popupType = popupType;
    this.popupHeading = popupHeading;
    this.popupText = popupText;
    this.OverlayVisible = true;
    this.openPopup = true;
    this.isInfoPopup = popupType === PopupTypeConstants.info;
    this.cdr.detectChanges();
  }
  closeAllPopups(btn = {}) {
    typeof this.popupConfig?.onPopupClose === "function" && this.popupConfig?.onPopupClose(btn);
    this.OverlayVisible = false;
    this.openPopup = false;
    this.cdr.detectChanges();
  }
  onActionBtnClick(btn) {
    typeof btn?.actionTrigger === "function" && btn?.actionTrigger();
    this.closeAllPopups(btn);
  }
}
